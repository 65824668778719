import React, { lazy } from "react";
import SuspenseWrapper from "routes/SuspenseWrapper";

const Notification = SuspenseWrapper(
  lazy(() => import("components/NotificationStatus"))
);

const NotificationRoutes = {
  path: "notification",
  element: <Notification />,
};

export default NotificationRoutes;
