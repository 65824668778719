import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const StaffList = SuspenseWrapper(lazy(() => import("components/staff/StaffList")));
const StaffForm = SuspenseWrapper(lazy(() => import("components/staff/StaffForm")));
const StaffImportForm = SuspenseWrapper(lazy(() => import("components/staff/StaffImportForm")));
const StaffImportList = SuspenseWrapper(lazy(() => import("components/staff/StaffImportList")));
const StaffImportStatusList = SuspenseWrapper(lazy(() => import("components/staff/StaffImportStatusList")));
const StaffDocumentsList = SuspenseWrapper(lazy(() => import("components/documents/StaffDocumentsList")));
const StaffDocumentsForm = SuspenseWrapper(lazy(() => import("components/documents/StaffDocumentsForm")));
const StaffRecords = SuspenseWrapper(lazy(() => import("components/staff/StaffRecords")));
const StaffRecordsProfile = SuspenseWrapper(lazy(() => import("components/staff/StaffRecordsProfile")));
const DeviceStatusList = SuspenseWrapper(
  lazy(() => import("components/scholar/reports/DevicestatusList"))
);

const StaffRoutes = {
  path: "staff",
  element: <Outlet />,
  children: [
    {
      path: "list",
      element: <StaffList />,
    },
    {
      path: "add",
      element: <StaffForm />,
    },
    {
      path: "edit/:hash",
      element: <StaffForm />,
    },
    {
      path: "import-list",
      element: <StaffImportList />,
    },
    {
      path: "import",
      element: <StaffImportForm />,
    },
    {
      path: "import-status/:hash",
      element: <StaffImportStatusList />,
    },
    {
      path: "doc-list",
      element: <StaffDocumentsList />,
    },
    {
      path: "doc-add",
      element: <StaffDocumentsForm />,
    },
    {
      path: "doc-edit/:hash",
      element: <StaffDocumentsForm />,
    },
    {
      path: "records",
      element: <StaffRecords />,
    },
    {
      path: "records/profile",
      element: <StaffRecordsProfile />,
    },
    {
      path: "device-status",
      element: <DeviceStatusList  mode="T"/>,
    },
  ],
};

export default StaffRoutes;
