import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const FinancialYearList = SuspenseWrapper(lazy(() => import("components/accounts/FinancialYearList")));
const AccountList = SuspenseWrapper(lazy(() => import("components/accounts/AccountList")));
const AccountTypeList = SuspenseWrapper(lazy(() => import("components/accounts/AccountTypeList")));
const CompanyList = SuspenseWrapper(lazy(() => import("components/accounts/CompanyList")));
const CustomerList = SuspenseWrapper(lazy(() => import("components/accounts/CustomerList")));
const VendorList = SuspenseWrapper(lazy(() => import("components/accounts/VendorList")));
const VpaymentList = SuspenseWrapper(lazy(() => import("components/accounts/VpaymentList")));
const VreceiptList = SuspenseWrapper(lazy(() => import("components/accounts/VreceiptList")));
const VcontraList = SuspenseWrapper(lazy(() => import("components/accounts/VcontraList")));
const VjournalList = SuspenseWrapper(lazy(() => import("components/accounts/VjournalList")));
const InvoiceList = SuspenseWrapper(lazy(() => import("components/accounts/InvoiceList")));
const InvoiceForm = SuspenseWrapper(lazy(() => import("components/accounts/InvoiceForm")));
const BillList = SuspenseWrapper(lazy(() => import("components/accounts/BillList")));
const BillForm = SuspenseWrapper(lazy(() => import("components/accounts/BillForm")));
const TaxList = SuspenseWrapper(lazy(() => import("components/accounts/TaxList")));
const StateList = SuspenseWrapper(lazy(() => import("components/accounts/StateList")));
const DayBookList = SuspenseWrapper(lazy(() => import("components/accounts/DayBookList")));
const CashBookList = SuspenseWrapper(lazy(() => import("components/accounts/CashBookList")));


const AccountsRoutes = {
    path: "accounts",
    element: <Outlet />,
    children: [
        {
            path: "financial_year",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    index: true,
                    element: <FinancialYearList />,
                },
            ]
        },
        {
            path: "account",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    index: true,
                    element: <AccountList />,
                },
            ]
        },
        {
            path: "account-type",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    index: true,
                    element: <AccountTypeList />,
                },
            ]
        },
        {
            path: "company",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    index: true,
                    element: <CompanyList />,
                },
            ]
        },
        {
            path: "vendor",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    index: true,
                    element: <VendorList />,
                },
            ]
        },        
        {
            path: "customer",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    index: true,
                    element: <CustomerList />,
                },
            ]
        },        
        {
            path: "v-payment",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    index: true,
                    element: <VpaymentList />,
                },
            ]
        },        
        {
            path: "v-receipt",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    index: true,
                    element: <VreceiptList />,
                },
            ]
        },
        {
            path: "v-contra",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    index: true,
                    element: <VcontraList />,
                },
            ]
        },
        {
            path: "v-journal",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    index: true,
                    element: <VjournalList />,
                },
            ]
        },
        {
            path: "invoice",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    index: true,
                    element: <InvoiceList />,
                },{
                    path: "add",
                    index: true,
                    element: <InvoiceForm />,
                },
            ]
        },
        {
            path: "bill",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    index: true,
                    element: <BillList />,
                },{
                    path: "add",
                    index: true,
                    element: <BillForm />,
                },
            ]
        },
        {
            path: "state",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    index: true,
                    element: <StateList />,
                },
            ]
        },
        {
            path: "tax-config",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    index: true,
                    element: <TaxList />,
                }
            ]
        },
        {
            path: "cash-book",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    index: true,
                    element: <CashBookList />,
                }
            ]
        },
        {
            path: "day-book",
            element: <Outlet />,
            children: [
                {
                    path: "",
                    index: true,
                    element: <DayBookList />,
                }
            ]
        }
    ],
};
export default AccountsRoutes;
